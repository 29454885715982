<template>
  <div class="content">
    <!-- 内容 -->
    <template v-for="(item, i) in data">
      <div :key="i" class="contentArr">
        <div class="contentText">
          <div class="gang"></div>
          <h3>{{ item.title }}</h3>
          <p v-html="item.text"></p>
          <img :src="item.imgSrc" alt="" class="img1" />
        </div>
      </div>
    </template>
    <!-- 内容 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [
        {
          imgSrc: require("@/assets/img/hygiene-case1.jpg"),
          title: "儿童智慧接种系统",
          text: "秀圆果公司在2021年为安徽省某市疾控中心建设智慧儿童接种系统平台。系统是根据我国免疫规划工作的要求，结合基层接种门诊的工作需求和卫生部门的管理要求所研制开发，实现了由接种门诊的“管理型模式”向“服务型模式”进行了转变升级。<br>系统平台为接种门诊提供了一套为儿童家长提供了一个自主、自助的疫苗接种服务，包括接种前的查询、建档、预约；接种时的自检、签字、在线缴费、接种叫号等；接种后的留观计划、下针预约、异常反应咨询等功能。",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.content {
  // 偶数列背景颜色
  .contentArr:nth-child(even) {
    background-color: #f4f4f4;
  }
}
@media screen and (max-width: 750px) {
  .content {
    // 内容样式
    .contentArr {
      background: #fff;
      width: 100%;
      overflow: hidden;
      .contentText {
        padding-bottom: 2.8rem;
        width: 15.87rem;
        margin: auto;
        margin-top: 1.6rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
        h3 {
          font-size: 0.92rem;
          font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
          font-weight: bold;
          color: #093c7f;
          margin-top: 0.1rem;
        }
        p {
          // text-indent: 0.48rem;
          font-size: 0.72rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #505050;
          line-height: 1.02rem;
        }
        .img1 {
          width: 15.87rem;
          height: 10.09rem;
          margin-top: 0.46rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  // 内容样式
  .contentArr {
    background: #fff;
    width: 19.2rem;
    overflow: hidden;
    .contentText {
      width: 100%;
      float: left;
      position: relative;
      padding-bottom: 2.8rem;
      margin: 1.6rem 0 0 1.69rem;
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
      h3 {
        height: 0.84rem;
        font-size: 0.5rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #093c7f;
        line-height: 0.7rem;
        margin-top: 0.05rem;
      }
      p {
        text-indent: 0.4rem;
        margin-top: 0.2rem;
        width: 8.71rem;
        font-size: 0.2rem;
        font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
        font-weight: 400;
        color: #000000;
        line-height: 0.36rem;
      }
      .img1 {
        width: 6.78rem;
        height: 4.32rem;
        position: absolute;
        top: 0.47rem;
        left: 9rem;
      }
      .care {
        height: 0.25rem;
        font-size: 0.18rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #093c7f;
        line-height: 0.21rem;
        margin-top: 0.2rem;
        .careContent {
          margin-bottom: 0.2rem;
        }
      }
    }
  }
}
</style>
